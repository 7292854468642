import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2024,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.September,
    year: 2024,
    offers: [
      {
        title: 'Voltaren',
        description: 'Schmerzgel, 120 g Gel',
        imgFile: 'voltaren-schmerzgel_ohne_gewicht.png',
        offerPrice: 14.99,
      },
      {
        title: 'Sinupret extrakt',
        description: 'bei Sinusitis, 20 Tabletten',
        imgFile: 'sinupret-extract.png',
        offerPrice: 12.99,
      },
      {
        title: 'Aspirin complex',
        description: 'bei Erkältung, 10 Beutel',
        imgFile: 'aspirin-complex.png',
        offerPrice: 8.99,
      },
    ],
  },
  {
    month: Month.October,
    moreMonths: [
      {
        year: 2024,
        month: Month.September,
      },
    ],
    year: 2024,
    offers: [
      {
        title: 'Otriven',
        description: 'Nasenspray, 10 ml',
        imgFile: 'otriven.png',
        offerPrice: 4.49,
      },
      {
        title: 'Sinupret extract',
        description: 'bei Sinusitis, 20 Tabletten',
        imgFile: 'sinupret-extract.png',
        offerPrice: 12.99,
      },
      {
        title: 'Gutschein',
        description:
          'Gutschein für eine Blutdruckmessung in der deutschen Herz-Woche (30.09.bis 05.10.2024).',
        imgFile: 'sphygmomanometer-915652.png',
        // offerPrice: 0.0,
      },
    ],
  },
];

export default offersCollection;
